import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useOperationMethod } from 'react-openapi-client';
// utils
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import { COGNITO_API } from '../config';

// ----------------------------------------------------------------------

// CAUTION: User Cognito is slily difference from firebase, so be sure to read the doc carefully.

export const UserPool = new CognitoUserPool({
  UserPoolId: COGNITO_API.userPoolId,
  ClientId: COGNITO_API.clientId,
});

const clientId = COGNITO_API.clientId;
const userPoolId = COGNITO_API.userPoolId;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  AUTHENTICATE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'cognito',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getMe, { loading, data, error }] = useOperationMethod('Me-get_me');
  const [user, setUser] = useState({ username: '', isAdmin: false });
  const [validToken, setValidToken] = useState(true);

  useEffect(() => {
    if (validToken === true) {
      const timer = setTimeout(() => {
        setValidToken(false);
      }, 3000000);
    }
  }, [validToken]);

  useEffect(() => {
    if (validToken === false) {
      const refreshToken = window.localStorage.getItem('refresh_token');

      const article = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;
      const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' };

      axios
        .post('https://logqs-test.auth.us-east-1.amazoncognito.com/oauth2/token', article, { headers })
        .then((response) => {
          window.localStorage.setItem('id_token', response.data.id_token);
          window.localStorage.setItem('access_token', response.data.access_token);
        })
        .catch((err) => {
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        });
      // Get user info
      getMe();
      setValidToken(true);
    }
  }, [validToken]);

  useEffect(() => {
    if (data && data.data) {
      setUser(data.data);
    }
  }, [data]);

  useEffect(() => {
    localStorage.setItem('lqs_admin_user', user.username);
  }, [user]);

  useEffect(() => {
    const accessToken = window.localStorage.getItem('access_token');
    if (accessToken !== null && accessToken !== '' && validToken === true) {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
  }, [user]);

  // http://localhost:3030/?code=c4a13702-008d-4611-ac9f-585bad28ef60
  // https://mydomain.auth.us-east-1.amazoncognito.com/oauth2/token&Content-Type='application/x-www-form-urlencoded'&grant_type=authorization_code&client_id=73mujtggpka7jcoaivg12dgt4h&code=c4a13702-008d-4611-ac9f-585bad28ef60&redirect_uri=http://localhost:3030
  //         axios.post("https://logqs-test.auth.us-east-1.amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=73mujtggpka7jcoaivg12dgt4h&code=f1e3e9ac-1994-463d-abf4-145db8ff2547&redirect_uri=http%3A%2F%2Flocalhost%3A3030",

  const getToken = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        params.delete('code');

        if (code !== null) {
          const clientId = '73mujtggpka7jcoaivg12dgt4h';
          const redirectUri = 'http%3A%2F%2Flocalhost%3A3030';

          const article = `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${redirectUri}`;
          const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' };

          axios
            .post('https://logqs-test.auth.us-east-1.amazoncognito.com/oauth2/token', article, { headers })
            .then((response) => {
              window.localStorage.setItem('id_token', response.data.id_token);
              window.localStorage.setItem('access_token', response.data.access_token);
              window.localStorage.setItem('refresh_token', response.data.refresh_token);
              setValidToken(true);
              window.location.replace(window.location.pathname);
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: 'AUTHENTICATE',
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            });
        } else if (
          window.localStorage.getItem('access_token') === null ||
          window.localStorage.getItem('access_token') === ''
        ) {
          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        } else {
          // Get user details
          getMe();

          dispatch({
            type: 'AUTHENTICATE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
      }),
    []
  );

  const initial = useCallback(async () => {
    try {
      await getToken();
    } catch {
      dispatch({
        type: 'AUTHENTICATE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getToken]);

  useEffect(() => {
    initial();
  }, [initial]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.
  const login = useCallback(
    () =>
      new Promise((resolve, reject) => {
        // I don't know if this will work
        window.location.replace(
          `https://logqs-test.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=${clientId}&redirect_uri=http://localhost:3030`
        );
      }),
    [getToken]
  );

  // same thing here
  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: 'LOGOUT' });
    }
  };
  // https://logqs-test.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=73mujtggpka7jcoaivg12dgt4h&redirect_uri=http://localhost:3030

  const register = (email, password, firstName, lastName) =>
    new Promise((resolve, reject) =>
      UserPool.signUp(
        email,
        password,
        [
          { Name: 'email', Value: email },
          { Name: 'name', Value: `${firstName} ${lastName}` },
        ],
        null,
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve();
          window.location.href = PATH_AUTH.login;
        }
      )
    );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user?.name || 'Minimals',
          role: 'admin',
          ...state.user,
        },
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
