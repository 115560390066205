import { hiIN } from '@mui/material/locale';
import { useContext, useEffect, useState } from 'react';
import { useOperationMethod } from 'react-openapi-client';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
import { AuthContext } from '../contexts/HostedUIAwsContext';
// import { AuthContext } from '../contexts/JWTContext';

// ----------------------------------------------------------------------

export default function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
}
