import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, isCollapse = false }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <>
      {isCollapse ? (
        <Box sx={{ width: 40, height: 40, ...sx }}>
          <img src="/logo/logqs_icon.png" alt="LogQS Logo" />
        </Box>
      ) : (
        <Box sx={{ width: 150, height: 40, ...sx }}>
          <img src="/logo/logqs_logo.png" alt="LogQS Logo" />
        </Box>
      )}
    </>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
